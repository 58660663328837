import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { deleteEstimate, fetchEstimatesList } from "../../requests/estimate";
import styles from "./index.module.scss";
import Item from "./listItem/item";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import { ESTIMATE_STATE } from "../../utils/booking.init";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import FilterByStatus from "../../components/filterByStatus/filterByStatus";
import { queryObject } from "../../types/query.types";
import useSaveQueryStorage from "../../hooks/useSaveQueryStorage";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Button from "../../components/Button/Button";
import ListPageHeader from "../referral/ListPageHeader/ListPageHeader";
import FiltersContainer from "../../components/Filters/FiltersContainer";
import ListHeader from "./ListHeader/ListHeader";
import clsx from "clsx";
import { useState } from "react";

let controller = new AbortController();

export const INITIAL_QUERY: queryObject = {
  field: "contact.phone",
  value: "",
  state: "",
  limit: 10,
  offset: 0,
};

const ESTIMATE_SEARCH_OPTIONS = [
  "contact.phone",
  "contact.displayname",
  "contact.organization",
  "contact.email",
];

export default function Estimates() {
  const [query, setQuery] = useSaveQueryStorage(INITIAL_QUERY, "estimate");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const { data, isLoading, refetch } = useQuery(
    ["estimate", query, sortDirection],
    () => fetchEstimatesList(formatedQuery()),
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  if (!data || !data.estimatesList) {
    return <>Error data is missing !</>;
  }

  return (
    <div className={styles["list-page"]}>
      <ListPageHeader
        title={"Demandes d'estimation"}
        actionSection={
          <Link onClick={() => window.scrollTo(0, 0)} to={"./create"}>
            <Button>+ Ajouter nouveau</Button>
          </Link>
        }
      />
      <FiltersContainer>
        <SearchByKeyComponent
          query={query}
          setQuery={setQuery}
          optionsList={ESTIMATE_SEARCH_OPTIONS}
        />
        <FilterByStatus
          query={query}
          setQuery={setQuery}
          optionsList={ESTIMATE_STATE}
          field={"status"}
        />
      </FiltersContainer>
      <div className={styles["list-container"]}>
        <div className={styles["paging-component-top"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
        <div className={styles["list"]}>
          <table className={styles["table"]}>
            <ListHeader onSort={toogleSort} sortDirection={sortDirection} />
            <tbody>
              {data.estimatesList.map((value: any, index: number) => {
                return (
                  <Item
                    key={index}
                    data={value}
                    index={query.limit - index}
                    onDelete={handleDelete}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={styles["paging-component"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
      </div>
    </div>
  );

  function toogleSort() {
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
  }

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery += `?limit=${query.limit}`;
    }

    formatedQuery += `&offset=${query.offset * query.limit}`;

    if (query.field && query.value) {
      formatedQuery += `&${query.field}=${query.value}`;
    }
    if (query.sort) {
      formatedQuery += `${query.sort}`;
    } else {
      formatedQuery += `&sort_field=createdAt&sort_direction=${sortDirection}`;
    }
    if (query.state && query.state !== "Tous") {
      formatedQuery += `&state=${query.state}`;
    }

    return formatedQuery;
  }

  async function handleDelete(id?: string) {
    controller.abort();
    try {
      controller = new AbortController();
      if (
        window.confirm("Voulez-vous supprimer cette demande d'estimation ?")
      ) {
        await deleteEstimate(id, controller.signal);
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  }
}
