import { ICart } from "../types/booking.type";
import { IContact, IProCart, IProduct } from "../types/proCart.types";
import { INITIAL_CONTACT } from "./shipping.init";

export const ESTIMATE_STATE = [
  "ESTIMATE_PENDING",
  "ESTIMATE_REACHED",
  "ESTIMATE_SENT",
  "ESTIMATE_TRANSFORMED",
  "ESTIMATE_CANCELED",
  "NON_FEASIBLE",
];

export const CART_STATE = [
  "CART_PENDING",
  "CART_IN_REPAIR",
  "CART_REPAIRED",
  "CART_COMPLETE",
  "CART_CANCELED",
];

export const INITIALE_CART: ICart = {
  useragent: "",
  comments: "",
  contact: INITIAL_CONTACT,
  productsList: [],
  createdAt: "",
  state: "CART_PENDING",
};

export const DEFAULT_PRO_CART: IProCart = {
  contact: INITIAL_CONTACT as IContact,
  state: "CART_PENDING",
  productsList: [],
  comments: "",
  shopId: "",
  id: "",
};

export const DEFAULT_PRO_PRODUCT: IProduct & { canceled: false } = {
  productGroup: "SHOES",
  operationGroup: "SHOES_MAN",
  workshopId: "",
  operationsList: [],
  brand: "",
  color: "",
  canceled: false,
  state: "PENDING_SHELVED",
};
