const STATUS_COLOR = new Map([
  ["LOGISTIC_INCOMING", "#eeb155"],
  ["LOGISTIC_QUOTE_ESTIMATION", "#eeb155"],
  ["LOGISTIC_QUOTE_SENT", "#ee8a55"],
  ["LOGISTIC_QUOTE_VALIDATON", "#ee8a55"],
  ["LOGISTIC_WORKSHOP", "#bb5733"],
  ["LOGISTIC_OUTGOING", "#4cb263"],
  ["LOGISTIC_DONE", "#4cb263"],

  ["INCOMING_PENDING", "#eeb155"],

  ["PENDING_SHELVED", "#eeb155"],
  ["EXTERNAL_JOB", "#b855ee"],
  ["WORKSHOP_REPAIR", "#78330b"],
  ["WORKSHOP_CARE", "#78330b"],
  ["WORKSHOP_COMPLETE", "#4cb263"],
  ["PRODUCT_CONTROLLED", "#4cb263"],
  ["WORKSHOP_OUTGOING", "#4cb263"],

  ["UNKNOWN", "#767789"],
  ["PENDING", "#eeb155"],
  ["TRANSFORMED", "#4cb263"],
  ["DELIVERED", "#4cb263"],
  ["COMPLETE", "#4cb263"],

  ["QUOTE_TO_GENERATE", "#A1A1A1"],
  ["QUOTE_DRAFT", "#A1A1A1"],
  ["QUOTE_PENDING", "#eeb155"],
  ["QUOTE_SENT", "#0066cc"],
  ["QUOTE_VALIDATED", "#4cb263"],
  ["QUOTE_CANCELED", "#e85454"],

  ["TRANSITING", "#0066cc"],

  ["DRAFT", "#767789"],
  ["REFURBISHED", "#bb5733"],
  ["COLLECTED", "#0066cc"],
  ["CONTROLLED", "#9CD326"],

  ["ESTIMATE_PENDING", "#eeb155"],
  ["ESTIMATE_REACHED", "#767789"],
  ["ESTIMATE_SENT", "#0066cc"],
  ["ESTIMATE_TRANSFORMED", "#4cb263"],
  ["ESTIMATE_CANCELED", "#e85454"],
  ["NON_FEASIBLE", "#767789"],

  ["CART_PENDING", "#eeb155"],
  ["CART_VALIDATED", "#4cb263"],
  ["CART_CANCELED", "#e85454"],
  ["CART_IN_REPAIR", "#bb5733"],
  ["CART_REPAIRED", "#0066cc"],
  ["CART_COMPLETE", "#4cb263"],

  ["CANCELED", "#e85454"],
  ["INCOMING_DELIVERED", "#4cb263"],
  ["PAYED", "#4cb263"],
  ["DELIVERED", "#4cb263"],

  ["OUTGOING", "#0066cc"],
  ["INCOMING", "#4cb263"],

  ["AUTHORISED", "#4cb263"],
  ["REFUSED", "#e85454"],
]);

export default function getStatusColor(data: string | undefined): string {
  if (data) return STATUS_COLOR.get(data) ?? data;
  return "#ff0000";
}
