export const TRACKING_STATE = [
  "UNKNOWN",
  "ESTIMATE_PENDING",
  "ESTIMATE_REACHED",
  "ESTIMATE_SENT",
  "CART_PENDING",
  "INCOMING_PENDING",
  "INCOMING_TRANSITING",
  "INCOMING_DELIVERED",
  "COLLECTED",
  "QUOTE_SENT",
  "PAYED",
  "REFURBISHED",
  "CONTROLLED",
  "DELIVERED",
  "CANCELED",
  "NON_FEASIBLE",
];

const TRANSLATION_TABLE = new Map([
  ["UNKNOWN", "Inconnu"],
  ["ESTIMATE_PENDING", "Pré-demande à traiter"],
  ["ESTIMATE_SENT", "Pré-demande traitée"],
  ["CART_PENDING", "Panier en cours"],
  ["INCOMING_PENDING", "Collecte en attente"],
  ["INCOMING_TRANSITING", "Collecte en transition"],
  ["INCOMING_DELIVERED", "Collecte reçue"],
  ["COLLECTED", "Collecté"],
  ["NON_FEASIBLE", "Non faisable"],
  ["PAYED", "Payé"],
  ["REFURBISHED", "Réparé"],
  ["ESTIMATE_PENDING", "Estimation en attente"],
  ["ESTIMATE_SENT", "Estimation envoyé"],
  // ["CART_PENDING", "Panier en attente"],
  ["CONTROLLED", "Contrôlé"],
  ["QUOTE_SENT", "Devis envoyé"],
  ["DELIVERED", "Livré"],
  ["CANCELED", "Annulé"],
  ["LOGISTIC_QUOTE_SENT", "Devis envoyé"],
  ["ESTIMATE_REACHED", "Estimation contacté"],
]);

export function translateTrackingState(data: string): string {
  return TRANSLATION_TABLE.get(data) ?? data;
}
