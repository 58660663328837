import style from "./item.module.scss";
import { Link } from "react-router-dom";

import { IOperation } from "../../../types/accounting.types";
import translate from "../../../translation";
import { Delete, Edit } from "@mui/icons-material";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import Select from "../../../components/Select/Select";
import { OPERTATION_TYPE } from "../../../utils/logistic.init";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import { toast } from "react-toastify";
import { updateOperation } from "../../../requests/operation";

interface ItemProps {
  data: IOperation;
  refetch: () => void;
  onDelete: (id: string) => void;
}

export default function Item({ data, refetch, onDelete }: ItemProps) {
  return (
    <div className={style["item"]}>
      <div className={style["item-element"]}>{data.description}</div>
      <div className={style["item-element"]}>
        {data.operationGroup && translate(data.operationGroup)}
      </div>
      <div className={style["item-element"]}>
        {data.operationType && (
          <Select
            optionsList={OPERTATION_TYPE}
            value={translate(data.operationType)}
            setValue={updateOperationType}
          />
        )}
      </div>
      <div className={style["item-element"]}>
        {data.productGroup && translate(data.productGroup)}
      </div>
      <div className={style["item-element"]}>{data.price} €</div>
      <div className={style["item-element"]}>{data.comments}</div>
      <ProtectedComponent roles={["ROLE_ADMIN"]}>
        <div className={`${style["item-element"]} ${style["tools"]}`}>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            className={style.mylink}
            to={`/operation-b2c/edit/${data.slug}`}
          >
            <Edit className={style["icon"]} />
          </Link>
          <Delete className={style["icon"]} onClick={handleDelete} />
        </div>
      </ProtectedComponent>
    </div>
  );

  function handleDelete() {
    if (
      window.confirm("Êtes-vous sûr de vouloir supprimer cette opération ?")
    ) {
      onDelete(data.slug);
    }
  }

  async function updateOperationType(operationType: string) {
    try {
      const response = await updateOperation({ ...data, operationType });
      toast.success(
        "Type d'operation mis à jour avec succes.",
        TOAST_SUCCESS_OPTIONS
      );
      refetch();
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }
}
