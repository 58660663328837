import clsx from "clsx";
import styles from "./listHeader.module.scss";
import { ArrowDropUp } from "@mui/icons-material";

interface ListHeaderProps {
  sortDirection: "asc" | "desc";
  onSort: () => void;
}

export default function ListHeader({ sortDirection, onSort }: ListHeaderProps) {
  return (
    <thead className={styles["list-header"]}>
      <tr className={styles["list-header-row"]}>
        <th
          className={clsx(styles["list-header-cell"], styles["sort-cell"])}
          onClick={onSort}
        >
          <p>Date de création</p>
          {sortDirection === "asc" && <ArrowDropUp />}
        </th>
        <th className={styles["list-header-cell"]}>Nom / Organisation</th>
        <th
          className={clsx(
            styles["list-header-cell"],
            styles["center-cell"],
            styles["product-cell"]
          )}
        >
          Articles
        </th>
        <th className={clsx(styles["list-header-cell"], styles["center-cell"])}>
          Status
        </th>
        <th className={clsx(styles["list-header-cell"], styles["center-cell"])}>
          Actions
        </th>
      </tr>
    </thead>
  );
}
